@font-face {
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/icons/icomoon.woff?dk6fuk') format('woff');
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-camera:before {
  content: '\e900';
}
.icon-mute:before {
  content: '\e901';
}
.icon-share-screen:before {
  content: '\e902';
}
.icon-whiteboard:before {
  content: '\e903';
}
.icon-end-session:before {
  content: '\e904';
}
.icon-invite:before {
  content: '\e905';
}
.icon-settings:before {
  content: '\e906';
}
.icon-participants:before {
  content: '\e907';
}
.icon-chat:before {
  content: '\e908';
}
.icon-content-library:before {
  content: '\e909';
}
.icon-pin:before {
  content: '\e90a';
}
.icon-unpin:before {
  content: '\e90b';
}
.icon-back:before {
  content: '\e90c';
}
.icon-add:before {
  content: '\e90d';
}
.icon-close:before {
  content: '\e90e';
}
.icon-new-folder:before {
  content: '\e90f';
}
.icon-upload:before {
  content: '\e910';
}
.icon-settings1:before {
  content: '\e911';
}
.icon-preview-file:before {
  content: '\e912';
}
.icon-move-to:before {
  content: '\e913';
}
.icon-rename:before {
  content: '\e914';
}
.icon-download:before {
  content: '\e915';
}
.icon-delete:before {
  content: '\e916';
}
.icon-selected:before {
  content: '\e917';
}
.icon-share:before {
  content: '\e918';
}
.icon-lock:before {
  content: '\e919';
}
.icon-unlock:before {
  content: '\e91a';
}
.icon-mute-small:before {
  content: '\e91b';
}
.icon-move-to-audience:before {
  content: '\e91c';
}
.icon-move-to-speakers:before {
  content: '\e91d';
}
.icon-remove:before {
  content: '\e91e';
}
.icon-approve-solid:before {
  content: '\e91f';
}
.icon-delete-solid:before {
  content: '\e920';
}
.icon-approve-outlined:before {
  content: '\e921';
}
.icon-delete-outlined:before {
  content: '\e922';
}
.icon-edit:before {
  content: '\e923';
}
.icon-save-to-medialibrary:before {
  content: '\e924';
}
.icon-bold-text:before {
  content: '\e925';
}
.icon-italic-text:before {
  content: '\e926';
}
.icon-underlined-text:before {
  content: '\e927';
}
.icon-attache-image:before {
  content: '\e928';
}
.icon-smiley:before {
  content: '\e929';
}
.icon-smiley-people:before {
  content: '\e92a';
}
.icon-smiley-nature:before {
  content: '\e92b';
}
.icon-smiley-food:before {
  content: '\e92c';
}
.icon-smiley-sports:before {
  content: '\e92d';
}
.icon-smiley-travel:before {
  content: '\e92e';
}
.icon-smiley-objects:before {
  content: '\e92f';
}
.icon-smiley-symbols:before {
  content: '\e930';
}
.icon-smiley-flags:before {
  content: '\e931';
}
.icon-compact-mode:before {
  content: '\e932';
}
.icon-normal-mode:before {
  content: '\e933';
}
.icon-muted:before {
  content: '\e934';
}
.icon-screen-share-placeholder:before {
  content: '\e935';
}
.icon-share1:before {
  content: '\e936';
}
.icon-pause:before {
  content: '\e937';
}
.icon-play:before {
  content: '\e938';
}
.icon-sound:before {
  content: '\e939';
}
.icon-no-sound:before {
  content: '\e93a';
}
.icon-prev-page:before {
  content: '\e93b';
}
.icon-next-page:before {
  content: '\e93c';
}
.icon-standard-view:before {
  content: '\e93d';
}
.icon-fit-to-content:before {
  content: '\e93e';
}
.icon-full-screen:before {
  content: '\e93f';
}
.icon-zoom-out:before {
  content: '\e940';
}
.icon-zoom-in:before {
  content: '\e941';
}
.icon-speakers:before {
  content: '\e942';
}
.icon-mic:before {
  content: '\e943';
}
.icon-dropdown-arrow:before {
  content: '\e944';
}
.icon-link:before {
  content: '\e945';
}
.icon-avatar:before {
  content: '\e947';
}
.icon-group-avatar:before {
  content: '\e948';
}
.icon-inchat-avatar:before {
  content: '\e949';
}
.icon-unmute-small:before {
  content: '\e94a';
}
.icon-open-artboard:before {
  content: '\e94b';
}
.icon-actual-size:before {
  content: '\e94c';
}
.icon-zoom-to-fit:before {
  content: '\e94d';
}
.icon-mode-switch:before {
  content: '\e94e';
}
.icon-minimize:before {
  content: '\e94f';
}
.icon-recording-base:before {
  content: '\e950';
}
.icon-recording-bulb-indicator:before {
  content: '\e951';
}
.icon-rec:before {
  content: '\e952';
}
.icon-qa:before {
  content: '\e953';
}
.icon-strikethrough-text:before {
  content: '\e954';
}
.icon-clear:before {
  content: '\e955';
}
.icon-question:before {
  content: '\e956';
}
.icon-upvote:before {
  content: '\e957';
}
.icon-live-answer:before {
  content: '\e958';
}
.icon-answer:before {
  content: '\e959';
}
.icon-qa-ask-questionsvg:before {
  content: '\e95a';
}
.icon-sorting-descend:before {
  content: '\e95b';
}
.icon-sorting-ascend:before {
  content: '\e95c';
}
.icon-filter:before {
  content: '\e95d';
}
.icon-checkbox-1:before {
  content: '\e95e';
}
.icon-checkbox-2:before {
  content: '\e95f';
}
.icon-radio-1:before {
  content: '\e960';
}
.icon-radio-2:before {
  content: '\e961';
}
.icon-live-answer1:before {
  content: '\e962';
}
.icon-request-public-vote:before {
  content: '\e963';
}
.icon-add-youtube-video:before {
  content: '\e964';
}
.icon-select-files:before {
  content: '\e965';
}
.icon-cancel-selection:before {
  content: '\e966';
}
.icon-media-tab:before {
  content: '\e967';
}
.icon-poll-tab:before {
  content: '\e968';
}
.icon-closed-folder-arrow:before {
  content: '\e969';
}
.icon-opened-folder-arrow:before {
  content: '\e96a';
}
.icon-shared-message:before {
  content: '\e96b';
}
.icon-poll:before {
  content: '\e96c';
}
.icon-poll-group:before {
  content: '\e96d';
}
.icon-add-question:before {
  content: '\e96e';
}
.icon-add-answer:before {
  content: '\e96f';
}
.icon-group-menu:before {
  content: '\e970';
}
.icon-ungroup-menu:before {
  content: '\e971';
}
.icon-subpoll:before {
  content: '\e972';
}
.icon-votes-counter:before {
  content: '\e973';
}
.icon-queued-live-badge:before {
  content: '\e974';
}
.icon-retry:before {
  content: '\e975';
}
.icon-shared-content:before {
  content: '\e976';
}
.icon-create:before {
  content: '\e977';
}
.icon-share-content:before {
  content: '\e978';
}
.icon-password:before {
  content: '\e979';
}
.icon-copy:before {
  content: '\e97a';
}
.icon-closed-caption:before {
  content: '\e97b';
}
.icon-breadcrumb-arrow:before {
  content: '\e97c';
}
.icon-phone-connected:before {
  content: '\e97d';
}
.icon-phone-disconnected:before {
  content: '\e97e';
}
.icon-phone-connecting-1:before {
  content: '\e97f';
}
.icon-phone-connecting-2:before {
  content: '\e980';
}
.icon-phone-connecting-3:before {
  content: '\e981';
}
.icon-phone-failed-1:before {
  content: '\e982';
}
.icon-phone-failed-2:before {
  content: '\e983';
}
.icon-end-call:before {
  content: '\e984';
}
.icon-avatar-photo:before {
  content: '\e985';
}
.icon-upvote1:before {
  content: '\e986';
}
.icon-remove-from-group:before {
  content: '\e987';
}
.icon-hide:before {
  content: '\e988';
}
.icon-start:before {
  content: '\e989';
}
.icon-stop:before {
  content: '\e98a';
}
.icon-edited-message:before {
  content: '\e98b';
}
.icon-presentation-permission:before {
  content: '\e98c';
}
.icon-broadcast-permission:before {
  content: '\e98d';
}
.icon-merge:before {
  content: '\e98e';
}
.icon-unmerge:before {
  content: '\e98f';
}
.icon-phone-mute:before {
  content: '\e990';
}
.icon-phone-hold:before {
  content: '\e991';
}
.icon-phone-disconnect:before {
  content: '\e992';
}
.icon-phone-number-small:before {
  content: '\e993';
}
.icon-phone-avatar-placeholder:before {
  content: '\e994';
}
.icon-hold-all-users:before {
  content: '\e995';
}
.icon-color-spectrum-arrow:before {
  content: '\e996';
}
.icon-color-picker-circle:before {
  content: '\e997';
}
.icon-text-align-left:before {
  content: '\e998';
}
.icon-text-align-center:before {
  content: '\e999';
}
.icon-text-align-right:before {
  content: '\e99a';
}
.icon-text-align-left-justified:before {
  content: '\e99b';
}
.icon-text-align-center-justified:before {
  content: '\e99c';
}
.icon-text-align-right-justified:before {
  content: '\e99d';
}
.icon-undo:before {
  content: '\e99e';
}
.icon-streaming:before {
  content: '\e99f';
}
.icon-stop-streaming:before {
  content: '\e9a0';
}
.icon-raise-hand:before {
  content: '\e9a1';
}
.icon-user-arrow-right:before {
  content: '\e946';
}
.icon-user-arrow-left:before {
  content: '\e9a2';
}
.icon-breakout-rooms:before {
  content: '\e9a4';
}
.icon-join:before {
  content: '\e9a3';
}
.icon-leave:before {
  content: '\e9a5';
}
.icon-broken-file:before {
  content: '\e9a7';
}
.icon-invite-speakers:before {
  content: '\e9a8';
}
.icon-file-loader:before {
  content: '\e9a9';
}
.icon-minimize1:before {
  content: '\e9aa';
}
.icon-restore:before {
  content: '\e9ab';
}
.icon-forced-layout:before {
  content: '\e9ac';
}
.icon-arrow:before {
  content: '\e9ad';
}
.icon-lock1:before {
  content: '\e9ae';
}
.icon-filmstrip-mode:before {
  content: '\e9af';
}
.icon-tiled-mode:before {
  content: '\e9b0';
}
.icon-thumbnail-mode:before {
  content: '\e9b1';
}
.icon-edit-mode:before {
  content: '\e9b2';
}
.icon-shared-notes:before {
  content: '\e9b3';
}
.icon-waiting:before {
  content: '\e9b4';
}
.icon-editing-notes:before {
  content: '\e9b5';
}
.icon-favorites-layouts:before {
  content: '\e9b6';
}
.icon-url-file:before {
  content: '\e9b7';
}
.icon-ban:before {
  content: '\e9b8';
}
.icon-web-app:before {
  content: '\e9b9';
}
.icon-solo-video:before {
  content: '\e9ba';
}
.icon-mute1:before {
  content: '\e9bb';
}
.icon-locked-download:before {
  content: '\e9bc';
}
.icon-unlocked-download:before {
  content: '\e9bd';
}
.icon-mobile-user:before {
  content: '\e9be';
}
.icon-camera-off:before {
  content: '\e9bf';
}
.icon-warning-sign-circle:before {
  content: '\e9c0';
}
.icon-warning-sign-exclemation:before {
  content: '\e9c1';
}
.icon-move-to-breakout:before {
  content: '\e9c2';
}
.icon-swap:before {
  content: '\e9c3';
}
.icon-unassign-breakout:before {
  content: '\e9c4';
}
.icon-search:before {
  content: '\e9c5';
}
.icon-reshuffle:before {
  content: '\e9c6';
}
.icon-warning:before {
  content: '\e9c7';
}
.icon-forward:before {
  content: '\e9c8';
}
.icon-minify:before {
  content: '\e9c9';
}
.icon-maximize:before {
  content: '\e9ca';
}
.icon-exit-fullscreen:before {
  content: '\e9cb';
}
.icon-fullscreen:before {
  content: '\e9cc';
}
.icon-e2ee:before {
  content: '\e9cd';
}
.icon-polls:before {
  content: '\e9ce';
}
.icon-media-library:before {
  content: '\e9cf';
}
.icon-chat-send:before {
  content: '\e9d0';
}
.icon-active-speaker-mode:before {
  content: '\e9d1';
}
.icon-virtual-bg-no-bg:before {
  content: '\e9d2';
}
.icon-virtual-bg-small-blur:before {
  content: '\e9d3';
}
.icon-virtual-bg-strong-blur:before {
  content: '\e9d4';
}
.icon-roles:before {
  content: '\e9d5';
}
.icon-e2ee-1:before {
  content: '\e9d6';
}
.icon-live-answer2:before {
  content: '\e9d7';
}
.icon-file:before {
  content: '\e9d8';
}
.icon-files:before {
  content: '\e9d9';
}
.icon-thumbnail-panel:before {
  content: '\e9da';
}
.icon-clone:before {
  content: '\e9db';
}
.icon-relaunch:before {
  content: '\e9dc';
}
.icon-user-check:before {
  content: '\e9a6';
}
.icon-user-check-box:before {
  content: '\e9dd';
}
.icon-box-arrow:before {
  content: '\e9de';
}
.icon-minus:before {
  content: '\e9df';
}
.icon-drag:before {
  content: '\e9e0';
}
.icon-transcript-3:before {
  content: '\e9e1';
}
.icon-microphone-text-2:before {
  content: '\e9e2';
}
.icon-pdf-file:before {
  content: '\e9e3';
}
.icon-image-file:before {
  content: '\e9e4';
}
.icon-folder:before {
  content: '\e9e5';
}
.icon-content-folder-1:before {
  content: '\e9e6';
}
.icon-folder-add:before {
  content: '\e9e7';
}
.icon-file-add:before {
  content: '\e9e8';
}
.icon-file-link:before {
  content: '\e9e9';
}
.icon-eye:before {
  content: '\e9ea';
}
.icon-link-file:before {
  content: '\e9eb';
}
.icon-pip:before {
  content: '\e9ec';
}
.icon-pip-exit:before {
  content: '\e9ed';
}
.icon-file-arrow-open:before {
  content: '\e9ee';
}
.icon-whiteboard-file:before {
  content: '\e9ef';
}
.icon-eye-crossed:before {
  content: '\e9f0';
}
.icon-whiteboard-eye:before {
  content: '\e9f1';
}
.icon-whiteboard-create:before {
  content: '\e9f2';
}
.icon-whiteboard-arrow:before {
  content: '\e9f3';
}
.icon-folder-arrow:before {
  content: '\e9f4';
}
.icon-folder-outline:before {
  content: '\e9f5';
}
.icon-browser-code:before {
  content: '\e9f6';
}
.icon-file-youtube:before {
  content: '\e9f7';
}
.icon-file-vimeo:before {
  content: '\e9f9';
}
.icon-file-code:before {
  content: '\e9fa';
}
.icon-doc-file:before {
  content: '\e9f8';
}
.icon-xls-file:before {
  content: '\e9fb';
}
.icon-ppt-file:before {
  content: '\e9fc';
}
.icon-group:before {
  content: '\e9fd';
}
.icon-exit-fullscreen1:before {
  content: '\e9fe';
}
.icon-enter-fullscreen:before {
  content: '\e9ff';
}
